export default [
  {
    path: "/app/school",
    component: () => import("../views"),
    meta: {
      title: "Educasis - Escola",
      requiresAuth: true,
    },
    children: [
      /* Register */
      /* Students */
      {
        path: "register/student",
        name: "school.register.student",
        component: () => import("../register/students/views/List"),
      },
      {
        path: "register/student/create",
        name: "school.register.student.create",
        component: () => import("../register/students/views/Create"),
      },
      /* Institution */
      {
        path: "register/institution",
        name: "school.register.institution",
        component: () => import("../register/institution/views/List"),
      },
      {
        path: "register/institution/create",
        name: "school.register.institution.create",
        component: () => import("../register/institution/views/Create"),
      },
      {
        path: "register/institution/edit/:id",
        name: "school.register.institution.edit",
        component: () => import("../register/institution/views/Edit"),
      },
       /* Benefits */
      {
        path: "register/type/benefits",
        name: "school.register.type.benefits",
        component: () => import("../register/type/benefits/views/List"),
      },
      {
        path: "register/type/benefits/create",
        name: "school.register.type.benefits.create",
        component: () => import("../register/type/benefits/views/Create"),
      },
      {
        path: "register/type/benefits/edit/:id",
        name: "school.register.type.benefits.edit",
        component: () => import("../register/type/benefits/views/Edit"),
      },
      /* Bncc */
      {
        path: "bncc",
        name: "school.bncc",
        component: () => import("../bncc/views/List.vue"),
      },
      {
        path: "bncc/edit/:id",
        name: "school.bncc.edit",
        component: () => import("../bncc/views/Edit.vue"),
      },
      {
        path: "bncc/add-many",
        name: "school.bncc.add-many",
        component: () => import("../bncc/views/AddMany.vue"),
      }
    ],
  },
];
