export default [
  {
    path: "/app/reports",
    component: () => import("../views"),
    meta: {
      title: "Educasis - Relatórios",
      requiresAuth: true,
    },
    children: [
      // Documents
      // Bulletins
      {
        path: "students/bulletin",
        name: "reports.students.bulletin",
        component: () =>
          import("../documents/bulletins/student/views/List.vue"),
      },
      {
        path: "teachers/bulletin",
        name: "reports.teachers-bulletin",
        component: () =>
          import("../documents/bulletins/teacher/views/List.vue"),
      },
      {
        path: "documents/class-diary",
        name: "documents.class-diary",
        component: () => import("../documents/class-diary/views/List.vue"),
      },
      // Documents
      {
        path: "planning",
        name: "documents.planning",
        component: () => import("../documents/planning/views/List.vue"),
      },
      // Reports
      {
        path: "result/finalresult",
        name: "reports.finalresult",
        component: () => import("../documents/result/finalresult/views/List.vue"),
      },
      // Registration
      {
        path: "teachers/students-per-class",
        name: "reports.students-per-class",
        component: () =>
          import("../reports/registration/students-per-class/views/List.vue"),
      },
      {
        path: "teachers/students-per-integration",
        name: "reports.students-per-integration",
        component: () =>
          import(
            "../reports/registration/students-per-integration/views/List.vue"
          ),
      },
      // Registers
      {
        path: "time-table",
        name: "reports.time-table",
        component: () =>
          import("../reports/registers/time-table/views/List.vue"),
      },
      {
        path: "deficiency",
        name: "reports.deficiency",
        component: () =>
          import("../reports/registers/deficiency/views/List.vue"),
      },
      {
        path: "benefits",
        name: "reports.benefits",
        component: () => import("../reports/registers/benefits/views/List.vue"),
      },
      // Absence Conference
      {
        path: "absence-conference",
        name: "reports.absence-conference",
        component: () =>
          import("../reports/releases/absence-conference/views/List.vue"),
      },
      // Reports
    ],
  },
];
