<template>
  <!-- Sidebar -->
  <div class="sidebar" id="sidebar">
    <div class="sidebar-inner">
      <perfect-scrollbar class="scroll-area" :settings="settings">
        <div id="sidebar-menu" class="sidebar-menu">
          <ul>
            <li
              v-for="(menu, mainIndex) in menus"
              :key="mainIndex"
              class="submenu"
            >
              <template v-if="menu.can_show">
                <a v-if="menu.submenu && !menu.link" href="javascript:void(0);">
                  <i :class="menu.icon"></i>
                  <span>{{ menu.mainMenu }}</span>
                  <span v-if="menu.submenu" class="menu-arrow"></span>
                </a>
                <router-link
                  v-else
                  :to="{ name: menu.link }"
                  :class="{ active: menu.link === currentPath }"
                >
                  <i :class="menu.icon"></i>
                  <span>{{ menu.mainMenu }}</span>
                </router-link>
                <ul v-if="menu.submenu">
                  <li
                    v-for="(childMenu, childIndex) in menu.submenuItems"
                    :key="childIndex"
                  >
                    <template v-if="childMenu.submenu">
                      <a href="javascript:void(0);" v-if="childMenu.can_show">
                        <div>
                          <i :class="childMenu.icon"></i>
                          <span>{{ childMenu.label }}</span>
                        </div>
                        <span class="menu-arrow"></span>
                      </a>
                      <ul>
                        <li
                          v-for="(grandChild,
                          grandChildIndex) in childMenu.submenuItems"
                          :key="grandChildIndex"
                        >
                          <router-link
                            :to="{ name: grandChild.link }"
                            :class="{ active: grandChild.link === currentPath }"
                            v-if="
                              grandChild.can
                                ? user.permissions[grandChild.can]
                                : false
                            "
                          >
                            {{ grandChild.label }}
                          </router-link>
                        </li>
                      </ul>
                    </template>
                    <template v-else>
                      <router-link
                        :to="{ name: childMenu.link }"
                        :class="{ active: childMenu.link === currentPath }"
                        v-if="
                          childMenu.can
                            ? user.permissions[childMenu.can]
                            : false
                        "
                      >
                        <i :class="childMenu.icon"></i> {{ childMenu.label }}
                      </router-link>
                    </template>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
  <!-- /Sidebar -->
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { menus, setUser } from "./menu";
import User from "@/modules/auth/models/User";

export default {
  components: {
    PerfectScrollbar
  },
  data() {
    return {
      settings: {
        suppressScrollX: true
      },
      activeClass: "active",
      // menus,
      user: User.query().first()
    };
  },
  mounted() {
    setUser(this.user);
    var Sidemenu = function() {
      this.$menuItem = $("#sidebar-menu a");
    };
    var $this = Sidemenu;
    $("#sidebar-menu a").on("click", function(e) {
      if (
        $(this)
          .parent()
          .hasClass("submenu")
      ) {
        e.preventDefault();
      }
      if (!$(this).hasClass("subdrop")) {
        $("ul", $(this).parents("ul:first")).slideUp(350);
        $("a", $(this).parents("ul:first")).removeClass("subdrop");
        $(this)
          .next("ul")
          .slideDown(350);
        $(this).addClass("subdrop");
      } else if ($(this).hasClass("subdrop")) {
        $(this).removeClass("subdrop");
        $(this)
          .next("ul")
          .slideUp(350);
      }
    });
    $("#sidebar-menu ul li.submenu a.active")
      .parents("li:last")
      .children("a:first")
      .addClass("active")
      .trigger("click");
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    isTeacher() {
      return this.user ? this.user.is_teacher : false;
    },
    permissions() {
      return this.user.permissions;
    },
    canShowCoordinationMenu() {
      return (
        !!this.permissions.view_absent_delay ||
        !!this.permissions.view_class_replacement_coordinator
      );
    },
    menus() {
      return [
        {
          mainMenu: "Coordenação",
          icon: "fas fa-id-badge",
          submenu: true,
          can_show: this.canShowCoordinationMenu,
          submenuItems: [
            {
              label: "Validação",
              icon: "fas fa-thumbs-up",
              submenu: true,
              can_show:
                !!this.permissions.view_validate_lesson_planning ||
                false ||
                !!this.permissions.view_validate_frequency ||
                false ||
                !!this.permissions.view_validate_lesson_planning_aee ||
                false ||
                !!this.permissions.view_validate_service_aee,
              submenuItems: [
                {
                  link: "coordination.validate-lesson-planning",
                  label: "Plano de Aula",
                  can: "view_validate_lesson_planning"
                },
                {
                  link: "coordination.validate-frequency",
                  label: "Frequência",
                  can: "view_validate_frequency"
                },
                {
                  link: "coordination.validate-lesson-planning-aee",
                  label: "Plano de Aula AEE",
                  can: "view_validate_lesson_planning_aee"
                },
                {
                  link: "coordination.validate-service-aee",
                  label: "Atendimento AEE",
                  can: "view_validate_service_aee"
                }
              ]
            },
            {
              label: "Falta/Atraso",
              icon: "fas fa-chalkboard",
              can: "view_absent_delay",
              link: "coordination.absent-delay",
              submenu: false
            },
            {
              label: "Reposição de Aulas",
              icon: "fas fa-chalkboard",
              can: "view_class_replacement_coordinator",
              link: "coordination.class-replacement",
              submenu: false
            }
          ]
        },
        {
          mainMenu: "Escola",
          icon: "fas fa-book",
          submenu: true,
          can_show: this.permissions.view_institution,
          submenuItems: [
            {
              label: "Cadastro",
              icon: "fas fa-user-plus",
              submenu: true,
              can_show: this.permissions.view_institution,
              submenuItems: [
                // {
                //   link: "school.register.student",
                //   label: "Alunos",
                //   can: "view_frequency",
                // },
                {
                  link: "school.register.institution",
                  label: "Instituição",
                  can: "view_institution"
                }
              ]
            },
            {
              label: "Tipos",
              icon: "fas fa-user-plus",
              submenu: true,
              can_show: this.permissions.view_benefit,
              submenuItems: [
                {
                  link: "school.register.type.benefits",
                  label: "Benefícios do Aluno",
                  can: "view_benefit"
                }
              ]
            }
          ]
        },
        {
          mainMenu: "Pessoas",
          icon: "fas fa-users",
          submenu: true,
          can_show:
            !!this.permissions.view_natural_person ||
            false ||
            !!this.permissions.view_color_race ||
            false ||
            !!this.permissions.view_person_deficiency ||
            false ||
            !!this.permissions.view_person_religion ||
            false,
          submenuItems: [
            {
              label: "Pessoa Física",
              icon: "fas fa-user",
              link: "people.natural-person",
              can: "view_natural_person"
            },
            {
              label: "Tipo",
              icon: "fas fa-list",
              submenu: true,
              can_show:
                !!this.permissions.view_color_race ||
                false ||
                !!this.permissions.view_person_deficiency ||
                false ||
                !!this.permissions.view_person_religion ||
                false,
              submenuItems: [
                {
                  link: "people.color-race",
                  label: "Cor ou Raça",
                  can: "view_color_race"
                },
                {
                  link: "people.deficiency",
                  label: "Deficiência",
                  can: "view_person_deficiency"
                },
                {
                  link: "people.religion",
                  label: "Religião",
                  can: "view_person_religion"
                }
              ]
            }
          ]
        },
        {
          mainMenu: "Professores",
          icon: "fas fa-chalkboard-teacher",
          submenu: true,
          can_show: true,
          submenuItems: [
            {
              label: "AEE",
              icon: "fas fa-chalkboard-teacher",
              submenu: true,
              can_show:
                this.permissions.view_lesson_planning_aee ||
                false ||
                this.permissions.view_record_aee ||
                false ||
                this.permissions.view_service_aee ||
                false,
              submenuItems: [
                {
                  link: "teacher.aee.planning",
                  label: "Plano de Aula AEE",
                  can: "view_lesson_planning_aee"
                },
                {
                  link: "teacher.aee.record-aee",
                  label: "Ficha AEE",
                  can: "view_record_aee"
                },
                {
                  link: "teacher.aee.service-aee",
                  label: "Atendimento AEE",
                  can: "view_service_aee"
                }
              ]
            },
            {
              label: "Atividades",
              icon: "fas fa-pencil-alt",
              can_show:
                this.permissions.view_activity_register ||
                false ||
                this.permissions.view_activity_note ||
                false,
              submenu: true,
              submenuItems: [
                {
                  link: "teacher.activity-register",
                  label: "Atividades da Turma",
                  can: "view_activity_register"
                },
                {
                  link: "teacher.activity-note.create",
                  label: "Nota das Atividades",
                  can: "view_activity_note"
                }
              ]
            },
            {
              label: "Diário de Classe",
              icon: "fas fa-book",
              submenu: true,
              can_show:
                this.permissions.view_frequency ||
                false ||
                this.permissions.view_notes ||
                false ||
                this.permissions.view_class_replacement ||
                false,
              submenuItems: [
                {
                  link: "teacher.frequencies",
                  label: "Frequência",
                  can: "view_frequency"
                },
                {
                  link: "teacher.frequencies.class-map",
                  label: "Mapa de Aulas",
                  can: "view_frequency"
                },
                {
                  link: "teacher.notes",
                  label: "Notas",
                  can: "view_note"
                },
                {
                  link: "teacher.class-replacement",
                  label: "Solicitar Reposição",
                  can: "view_class_replacement"
                }
              ]
            },
            {
              label: "Ocorrência",
              icon: "fas fa-exclamation-triangle",
              link: "teacher.registration.occurrence.disciplinary",
              can: "view_occurrence",
              submenu: false
            },
            {
              label: "Plano de Aula",
              icon: "fas fa-chalkboard",
              link: "teacher.planning",
              can: "view_lesson_planning",
              submenu: false
            }
          ]
        },
        {
          mainMenu: "Configurações",
          icon: "fas fa-cogs",
          submenu: true,
          can_show:
            !!this.permissions.view_user_type ||
            false ||
            !!this.permissions.view_chatbot ||
            false,
          submenuItems: [
            {
              label: "Bot",
              icon: "fas fa-robot",
              submenu: false,
              can: "view_chatbot",
              link: "bot.index"
            },
            {
              label: "Parâmetros",
              icon: "fas fa-asterisk",
              link: "settings.params",
              submenu: false,
              can: "view_user_type"
            },
            {
              label: "Tipos de Usuário",
              icon: "fas fa-users",
              link: "settings.user-type",
              submenu: false,
              can: "view_user_type"
            },
            {
              label: "Bncc",
              icon: "fas fa-cogs",
              link: "school.bncc",
              submenu: false,
              can: "view_user_type"
            }
          ]
        },
        {
          mainMenu: "Documentos",
          icon: "fas fa-folder",
          submenu: true,
          can_show:
            !!this.permissions.view_teacher_bulletin_report ||
            false ||
            !!this.permissions.view_student_bulletin_report ||
            false ||
            !!this.permissions.view_class_diary_report ||
            false,
          submenuItems: [
            {
              label: "Boletins",
              icon: "fas fa-users-cog",
              can_show:
                !!this.permissions.view_teacher_bulletin_report ||
                false ||
                !!this.permissions.view_student_bulletin_report ||
                false,
              submenu: true,
              submenuItems: [
                {
                  label: "Boletim do Aluno",
                  link: "reports.students.bulletin",
                  submenu: false,
                  can: "view_student_bulletin_report"
                },
                {
                  label: "Boletim do Professor",
                  link: "reports.teachers-bulletin",
                  submenu: false,
                  can: "view_teacher_bulletin_report"
                }
              ]
            },
            {
              label: "Diário de Classe",
              icon: "fas fa-chalkboard",
              link: "documents.class-diary",
              can: "view_class_diary_report",
              submenu: false
            },
            {
              label: "Resultados",
              icon: "fas fa-users-cog",
              can_show: !!this.permissions.view_report_result_final || false,
              submenu: true,
              submenuItems: [
                {
                  label: "Resultado final",
                  link: "reports.finalresult",
                  submenu: false,
                  can: "view_report_result_final"
                }
              ]
            },
            {
              label: "Plano de Aula",
              icon: "fas fa-chalkboard",
              link: "documents.planning",
              can: "view_lesson_planning",
              submenu: false
            }
          ]
        },
        {
          mainMenu: "Relatórios",
          icon: "fas fa-file",
          submenu: true,
          can_show: !!this.permissions.view_students_per_class_report || false,
          submenuItems: [
            {
              label: "Matrículas",
              icon: "fas fa-users-cog",
              can_show: true,
              submenu:
                !!this.permissions.view_students_per_class_report || false,
              submenuItems: [
                {
                  label: "Alunos por Turma",
                  link: "reports.students-per-class",
                  submenu: false,
                  can: "view_students_per_class_report"
                },
                {
                  label: "Alunos por Enturmação",
                  link: "reports.students-per-integration",
                  submenu: false,
                  can: "view_students_per_class_report"
                }
              ]
            },
            {
              label: "Cadastrais",
              icon: "fas fa-chalkboard-teacher",
              can_show:
                !!this.permissions.view_teacher_time_table_report || false,
              submenu: true,
              submenuItems: [
                {
                  label: "Quadro de Horários",
                  icon: "fas fa-chalkboard-teacher",
                  link: "reports.time-table",
                  submenu: false,
                  can: "view_teacher_time_table_report"
                },
                {
                  label: "Alunos com Deficiência",
                  icon: "fas fa-chalkboard-teacher",
                  link: "reports.deficiency",
                  submenu: false,
                  can: "view_report_deficiency"
                },
                {
                  label: "Alunos com Benefícios",
                  icon: "fas fa-chalkboard-teacher",
                  link: "reports.benefits",
                  submenu: false,
                  can: "view_report_benefits"
                }
              ]
            },
            {
              label: "Lançamentos",
              icon: "fas fa-file",
              submenu: true,
              can_show:
                !!this.permissions.view_absence_conference_report || false,
              submenuItems: [
                {
                  label: "Conferência de faltas",
                  icon: "fas fa-chalkboard-teacher",
                  link: "reports.absence-conference",
                  can: "view_absence_conference_report",
                  submenu: false
                }
              ]
            }
          ]
        }
      ];
    }
  }
};
</script>
<style>
.scroll-area {
  position: relative;
  margin: auto;
  height: calc(100vh - 60px);
  background-color: transparent !important;
}
</style>
